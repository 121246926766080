const interact_with_page = new Event('user_interact_with_page');

function remove_listeners() {
  document.removeEventListener('click', click_hundler);
  document.removeEventListener('keydown', handler_keydown);
  document.removeEventListener('touchend', touchend_handler);
}

function click_hundler() {
  window.dispatchEvent(interact_with_page);
  remove_listeners();
}

function handler_keydown(e) {
  const { key } = e;
  const keys_user_not_interract_with_page = ['NumLock', 'Control', 'Alt', 'Escape'];

  if (keys_user_not_interract_with_page.includes(key)) {
    return;
  }

  window.dispatchEvent(interact_with_page);
  remove_listeners();
}

function touchend_handler() {
  window.dispatchEvent(interact_with_page);
  console.log('User interact with page by touch');
  remove_listeners();
}

export function setupUserInteractWithPageEvent() {
  document.addEventListener('click', click_hundler);
  document.addEventListener('keydown', handler_keydown);
  document.addEventListener('touchend', touchend_handler);
}
